<script setup lang="ts">
import IconAveolaLogo from '@/shared/public/IconAveolaLogo.svg'

import { CookiesAccessModal } from '@/widgets/modal/cookies-access/modal'
import { CookiesAccessTopbar } from '@/widgets/modal/cookies-access/toolbar'
import { Preloader } from '@/widgets/preloader'

useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#F2F2F6',
    },
  ],
})
</script>

<template>
  <div>
    <Preloader />
    <client-only>
      <div class="bg-gray-200">
        <div class="min-h-svh flex flex-col lg:px-0 px-20 lg:max-w-344 mx-auto">
          <div class="flex justify-center shrink-0 grow-0">
            <IconAveolaLogo class="my-24" />
          </div>
          <div class="shrink grow w-full mx-auto flex flex-col">
            <slot />
          </div>
          <CookiesAccessTopbar />
          <CookiesAccessModal />
        </div>
      </div>
    </client-only>
  </div>
</template>
